import React from 'react'
import loadable from '@loadable/component'
import { Styled } from 'theme-ui'
import Prism from 'prismjs'
import 'prismjs/components/prism-ruby'

const ThemePrism = loadable(() => import('@theme-ui/prism'))

const CodeBlock = props => (
  // eslint-disable-next-line react/jsx-pascal-case
  <ThemePrism {...props} Prism={Prism} fallback={<Styled.pre>{props.children}</Styled.pre>} />
)

export default CodeBlock
